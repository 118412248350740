import * as Sentry from "@sentry/remix";

let IS_SENTRY_CONFIGURED = false;

export const configureSentryClient = () => {
  if (!IS_SENTRY_CONFIGURED) {
    // enable Sentry on the client
    Sentry.init({
      dsn: (window as any)?.ENV?.SENTRY_DSN || null,
      release: (window as any)?.ENV?.COMMIT_SHA || "unknown",
      environment: (window as any)?.ENV?.APP_ENV || "unknown",
      tracesSampleRate: 1,
      integrations: [
        // new Sentry.BrowserTracing({
        //   routingInstrumentation: Sentry.remixRouterInstrumentation(
        //     useEffect,
        //     useLocation,
        //     useMatches
        //   ),
        // }),
      ],
      // ...
    });
    IS_SENTRY_CONFIGURED = true;
  }
};
